<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
		></pui-datatable>
		<mtipoimplicado-modals
			:modelName="modelName"
		></mtipoimplicado-modals>
	</div>
</template>

<script>
import mtipoimplicadoActions from './MtipoimplicadoActions';
import mtipoimplicadoModals from './MtipoimplicadoModals.vue';

export default {
	name: 'mtipoimplicado-grid',
	components: {
		'mtipoimplicado-modals': mtipoimplicadoModals
	},
	data() {
		return {
			modelName: 'mtipoimplicado',
			actions: mtipoimplicadoActions.gridactions,
			modelColumnDefs: {
			}
		}
	}
}
</script>
